import IconButton from "components/blocks/IconButton";
import PageWithTabs from "components/PageWithTabs";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SPVMainInfo from "./tabs/SPVMainInfo";
import SPVStatistics from "./tabs/SPVStatistics";
import { useQuery } from "hooks/useQuery";
import { ScientificPublicationsService } from "services/scientificPublicationsService";
import { useCookieContext } from "contexts";
import Modal from "components/blocks/Modal";
import ActionModal from "components/blocks/Modal/ActionModal";
import useMutation from "hooks/useMutation";

type PublicationViewProps = {};

const PublicationView: React.FC<PublicationViewProps> = (props) => {
  // STATE
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  // HOOKS
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("ScientificPublication");
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();
  const currentLocale = isAr ? "Ar" : "En";

  const { mutateAsync: deleteAsync, loading: isDeleteLoading } = useMutation({
    queryFn: (pubId: number) => ScientificPublicationsService.delete(pubId),
  });

  const { data, loading: isLoading } = useQuery({
    queryFn: () => ScientificPublicationsService.getScientificPublicationById(Number(id)),
    onError: (error) => {
      console.log(error);
    },
    options: {
      enabled: !!id,
    },
  });

  // EFFECTS
  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "scientificPublication",
        path: "/home/scientificPublication",
        menuItemId: "scientificPublication",
      },
      {
        value: data?.scientificPublishingDetails.find((detail) => detail.locale === currentLocale)?.displayName ?? "",
        path: `/home/scientificPublication/${id}`,
      },
    ]);
  }, [id, data]);

  const onDeleteConfirm = async () => {
    await deleteAsync(Number(id));
    setDeleteModalVisible(false);
    navigate("/home/scientificPublication");
  };

  const onEditClicked = () => {
    navigate(`/home/scientificPublication/form/${id}`);
  };

  return (
    <>
      <PageWithTabs
        loading={isLoading}
        title={data?.scientificPublishingDetails.find((detail) => detail.locale === currentLocale)?.displayName}
        subTitle={t("scientificPublicationSerialNumber", { serialNumber: data?.serialNumber })}
        titleImgSrc={`https://apiGateway.aaltair.net/FileManagerApi/File/Download/${data?.scientificPublishingImage.id}`}
        defaultTabId="mainInfo"
        pageHeaders={[
          {
            title: t("publisher"),
            value: data?.authorName,
          },
          {
            title: t("publicationTypeCategory"),
            value: `${data?.typeOfScientific?.typeOfScientificDetail.displayName} - ${data?.scientificClassification?.scientificClassificationDetail.displayName}`,
          },
          {
            title: t("publicationLanguage"),
            value: data?.languageOfScientific?.languageOfScientificDetail.displayName,
          },
          {
            title: t("publicationDate"),
            value: toSystemThemeDateFormat(data?.publicationDate),
          },
        ]}
        actions={() => (
          <div className="d-flex gap-2">
            <IconButton icon="icon-share" variant="light" />
            <IconButton icon="icon-edit" variant="light" onClick={onEditClicked} />
            <IconButton
              icon="icon-delete"
              variant="light"
              innerIconColor="danger"
              onClick={() => setDeleteModalVisible(true)}
            />
          </div>
        )}
        tabs={[
          {
            id: "mainInfo",
            title: t("mainInfo"),
            content: !!data ? <SPVMainInfo data={data} /> : null,
          },
          {
            id: "statistics",
            title: t("statistics"),
            content: !!data ? <SPVStatistics data={data} /> : null,
          },
        ]}
      />
      <ActionModal
        isOpen={deleteModalVisible}
        onClose={() => {
          setDeleteModalVisible(false);
        }}
        headerMsg={t("deleteDialogTitle")}
        subMsg={t("deleteModalSubMsg", {
          name: data?.scientificPublishingDetails.find((x) => x.locale === (isAr ? "Ar" : "En"))?.displayName,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("Common:delete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onDeleteConfirm}
        loading={isDeleteLoading}
      />
    </>
  );
};

export default PublicationView;
